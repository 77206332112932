.wrapper {
  contain: content;

  & h2 {
    margin-bottom: var(--spacing-8);
  }
}

.layout {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  flex-wrap: wrap;
}

.usps {
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  padding: var(--spacing-10) var(--spacing-20);
  border: 1px solid var(--color-rac-grey-10);
  justify-content: space-evenly;

  @media (--mobile) {
    padding: var(--spacing-10) var(--spacing-5);
  }
}

.usp {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-4);
  align-items: flex-start;

  & p {
    margin: 0;
  }
}

.uspLayout {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.uspTitle {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);

  & h4 {
    margin: 0;
    font-size: 24px;

    @media (--mobile) {
      font-size: 18px;
    }
  }
}

.imageWrapper {
  flex: 1 1 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-rac-grey-10);
  position: relative;

  & img {
    position: relative !important;
    max-height: 400px;
  }
}
